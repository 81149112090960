import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import React from 'react';
import Redirect from '@shoreag/base/Redirect';
import { COGNITO_USER_ATTRIBUTES } from '@shoreag/helpers/constants';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  composeValidations,
  match,
  minLength,
  required,
} from '@shoreag/validations';
import { useMutation } from 'react-apollo';
import AuthContainer from '../../containers/AuthContainer';
import FormError from '../../components/FormError';
import SubmitButton from '../../components/SubmitButton';
import config from '../../config.json';
import updateUserMutation from '../../graphql/mutations/update-user.gql';
import { COGNITO_USER_ATTRIBUTES as LOCAL_COGNITO_USER_ATTRIBUTES } from '../../utilities/constants';
import Logo from '../../components/Logo';
import LoginIllustration from '../../images/illustrations/login.svg';

const CompleteSignupPage = () => {
  const [updateUser] = useMutation(updateUserMutation);

  return (
    <AuthContainer title="Complete Signup">
      {({ completeNewPasswordChallenge, newPasswordRequired }) => (
        <Box
          display={{ lg: 'flex' }}
          height={{ lg: '100%' }}
          minHeight="750px"
          sx={{ position: [null, null, null, null, 'absolute'] }}
          width={{ lg: '100%' }}
        >
          <Redirect to="/login" when={!newPasswordRequired} />
          <Box
            alignItems="center"
            bg={{ lg: 'white' }}
            display="flex"
            height={{ lg: '100%' }}
            justifyContent={{ _: 'center', lg: 'flex-end' }}
            width={{ lg: '50%' }}
          >
            <Logo
              sx={{
                left: '3rem',
                mt: '3rem',
                position: [null, null, null, null, 'absolute'],
                top: '0',
                width: '10rem',
              }}
            />
            <Box
              sx={{
                display: ['none', null, null, null, 'block'],
                maxWidth: '650px',
                position: 'relative',
                right: '-3rem',
                top: '2rem',
                width: '100%',
              }}
            >
              <LoginIllustration width="100%" />
            </Box>
          </Box>
          <Box
            alignItems="center"
            bg={{ lg: 'primary' }}
            display="flex"
            height={{ lg: '100%' }}
            justifyContent="center"
            px={5}
            py={8}
            width={{ lg: '50%' }}
          >
            <FinalForm
              initialValues={{}}
              onSubmit={async (values) => {
                try {
                  await completeNewPasswordChallenge({
                    [COGNITO_USER_ATTRIBUTES.FAMILY_NAME]: values.familyName,
                    [COGNITO_USER_ATTRIBUTES.GIVEN_NAME]: values.givenName,
                    [COGNITO_USER_ATTRIBUTES.PREFERRED_USERNAME]:
                      values.preferredUsername,
                    [LOCAL_COGNITO_USER_ATTRIBUTES.ORGANIZATION]:
                      values.organization,
                    password: values.newPassword,
                  });

                  await updateUser({
                    variables: { password: values.password },
                  });
                } catch (e) {
                  return { [FORM_ERROR]: e.message };
                }
              }}
              render={(formContext) => (
                <Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                  <Box
                    as="h1"
                    color={{ lg: 'white' }}
                    fontSize={7}
                    mb={6}
                    textAlign={{ _: 'center', lg: 'left' }}
                  >
                    Complete Sign-up
                  </Box>
                  <form onSubmit={formContext.handleSubmit}>
                    <Field
                      component={Input}
                      label="User Name"
                      name="preferredUsername"
                      validate={required}
                    />
                    <Field
                      component={Input}
                      label="First Name"
                      name="givenName"
                      validate={required}
                    />
                    <Field
                      component={Input}
                      label="Last Name"
                      name="familyName"
                      validate={required}
                    />
                    <Field
                      component={Input}
                      label="Organization"
                      name="organization"
                      validate={required}
                    />
                    <Field
                      component={Input}
                      label="New Password"
                      name="newPassword"
                      type="password"
                      validate={composeValidations(
                        minLength(config.minPasswordLength),
                        required
                      )}
                    />
                    <Field
                      component={Input}
                      label="Verify New Password"
                      name="verifiedPassword"
                      type="password"
                      validate={match(
                        'Passwords',
                        formContext.values.newPassword
                      )}
                    />
                    <FormError>{formContext.submitError}</FormError>
                    <SubmitButton submitting={formContext.submitting}>
                      Complete Signup
                    </SubmitButton>
                  </form>
                </Box>
              )}
            />
          </Box>
        </Box>
      )}
    </AuthContainer>
  );
};

export default CompleteSignupPage;
